.navbar {
  border: none;
  background-color: transparent;
  margin-bottom: 0;
  position: fixed;
  width: 100%;
}
.navbar-header-upper {
  > section {
    background-color: $color__blue;
    height: 3.125em;
    > .container {
      padding-right: 10px;
    }
  }
  background-color: $color__white;
  background-color: $color__ltgrayblue;
  color: $color__white;
  padding-bottom: 0.3125em;
  //@include background-2x( '/images/DiagonalLines_300px_tile', 'svg', 300px, 300px, center bottom, repeat );
  background: $color__ltgrayblue url(/images/DiagonalLines_300px_tile.svg)
    repeat center bottom;
  background-size: 300px 300px;
  background-size: 150px 150px;
}
.navbar-brand {
  text-indent: -9999em;
  //@include background-2x( '/images/CAM_logo_swoosh_MBL', 'svg', 126px, 64px, center top, no-repeat );
  background: transparent url(/images/CAM_logo_swoosh_MBL.svg) no-repeat center
    top;
  background-size: 126px 64px;
  width: 126px;
  height: 64px;
  position: relative;
  z-index: 10;
  display: block;
  cursor: pointer;
}
@media only screen and (min-width: 62em) {
  /* 992 =================================================== */
  .navbar {
    min-height: 96.5px;
    background-image: url(/images/DiagonalLines_300px_tile_DKTP.svg);
  }
  .navbar-header-upper {
    padding-bottom: 0.9375em;
    background-size: 300px 300px;
    > section {
      height: 2.34375em;
    }
  }
  .navbar-brand {
    background-image: url(/images/CAM_logo_swoosh_DKTP.svg) !important;
    background-size: 290px 148px;
    width: 290px;
    height: 148px;
    //margin-right: 1.5em;
    position: absolute;
  }
}

#utility-menu {
  ul {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    height: 50px;
    overflow: hidden;
  }
  > ul {
    max-width: 14.125em;
  }
  li {
    margin: 0.4375em 0 0.3125em;
    border-right: 1px solid $color__yellow;
    float: left;
    display: inline-block;
    &.menu-item-1 {
      order: 0;
      width: 124px;
    }
    &.menu-item-2 {
      order: 2;
      width: 124px;
      text-align: right;
    }
    &.menu-item-3 {
      order: 1;
      border-right: none;
    }
    &.menu-item-4 {
      order: 3;
      border-right: none;
    }
    &.menu-item-5 {
      order: 4;
      border-right: none;
    }
  }
  li:last-of-type {
    border-right: none;
    margin-left: 10px;
  }
  a {
    text-decoration: none;
    @include font-size(12/16);
    line-height: 1;
    font-family: "HelveticaNeueLTStd-LtCn";
    padding: 0 0.66666666666667em;
    display: block;
    &:link,
    &:visited {
      color: $color__white;
    }
    &:focus,
    &:hover,
    &:active {
      color: $color__yellow;
    }
  }
}

@media only screen and (min-width: 62em) {
  /* 992 =================================================== */
  #utility-menu {
    position: relative;
    left: 314px;
    left: 294px;
    > ul {
      max-width: 100%;
      height: auto;
    }
    li {
      margin: 0.625em 0;
      text-align: center;
      border-right-color: $color__white;
      &.menu-item-1 {
        order: 0;
        width: auto;
      }
      &.menu-item-2 {
        order: 1;
        width: auto;
        text-align: center;
      }
      &.menu-item-3 {
        order: 2;
        border-right: 1px solid $color__white;
      }
      &.menu-item-4 {
        order: 3;
        border-right: none;
      }
    }
    a {
      @include font-size(18/16);
      line-height: 1;
      font-family: "HelveticaNeueLTStd-Cn";
      padding: 0 0.55555555555556em;
    }
  }
}

.navbar-header {
  clear: both;
  background-color: $color__ltgrayblue;
  position: relative;
  top: -9px;
  margin-bottom: -14px;
  width: 100%;
  > .container {
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    align-content: center;
    align-items: center;
    position: relative;
    padding-bottom: 0.3125em;
    > h4 {
      order: 0;
      //width: 128px;
    }
    > .navbar-toggle {
      order: 2;
      flex: 1 1 auto;
    }
    > .navbar-collapse {
      order: 3;
      width: 100%;
    }
    > .savingsbug {
    }
  }
  h4 {
    font-family: "HelveticaNeueLTStd-Cn";
    color: $color__black;
    @include font-size(12/16);
    line-height: 1;
    text-align: center;
    width: 126px;
    text-transform: uppercase;
    float: left;
    margin-bottom: 0;
    //padding: 8px 16px 0;
  }
}

@media only screen and (min-width: 62em) {
  /* 992 =================================================== */
  .navbar-header {
    top: 0.9375em;
    margin-bottom: 0;
    > .container {
      padding: 0 0 0 325px;
      padding: 0 0 0 305px;
      position: initial;
      > .navbar-collapse {
        order: 0;
        width: auto;
        position: relative;
        margin: 0;
      }
      > h4 {
        width: auto;
        order: 1;
      }
      > .navbar-toggle {
        order: 2;
        width: 100%;
      }
      .savingsbug {
        order: 3;
      }
    }
    h4 {
      @include font-size(25/16);
      @include font-size(16/16);
      text-align: left;
      width: auto;
      margin-top: 0.625em;
      span {
        display: none;
      }
    }
  }
  _:-ms-fullscreen,
  :root .navbar-header > .container {
    position: static;
  }
}

.savingsbug {
  text-decoration: none;
  @include font-size(21/16);
  line-height: 1.0714285714286;
  text-transform: uppercase;
  display: inline-block;
  border-radius: 5px;
  font-family: "HelveticaNeueLTStd-BdCn";
  padding: 0.14285714285714em 0.76190476190476em 0.095238095238095em;
  margin: 0.19047619047619em 0 0.19047619047619em 0.28571428571429em;
  &:link,
  &:visited {
    background-color: $color__yellow;
    color: $color__blue;
    text-decoration: none;
  }
  &:hover,
  &:focus,
  &:active {
    color: $color__yellow !important;
    background-color: $color__blue;
    text-decoration: none;
  }
  .fa {
    @include font-size(14/16);
    padding-left: 8px;
    position: relative;
    top: -2px;
  }
}
@media only screen and (min-width: 62em) {
  /* 992 =================================================== */
  .savingsbug {
    position: absolute;
    bottom: -156px;
    right: -57px;
    border-radius: 0px;
    border: 2px solid $color__white;
    border-top: none;
    margin: 0;
    padding: 0.52380952380952em 1.7142857142857em;
    transform: rotate(90deg);
  }
}

.navbar-default .navbar-toggle {
  color: $color__magenta;
  padding: 0;
  margin: 4px 0;
  background-color: transparent;
  border: none;
  min-width: auto;
  &:hover,
  &:focus {
    background-color: transparent;
  }
  .icon-bar {
    background-color: $color__magenta;
    /*
		width: 1.375em;
		height: 0.15625em;
*/
    border-radius: 10px;
    margin-left: auto;
    margin-right: auto;
    height: 3px;
    + .icon-bar {
      //margin-top: 0.21875em;
    }
  }
}

.navbar-default .navbar-collapse {
  margin: 0px -13px;
  border: none;
  padding: 0;
}
.navbar-default .navbar-nav {
  margin: 0 20px;
  > li {
    border-bottom: 1px solid $color__yellow;
    padding: 0 1.09375em;
    > a {
      @include font-size(25/16);
      line-height: 3.06;
      text-transform: uppercase;
      font-family: "HelveticaNeueLTStd-BdCn";
      color: $color__magenta;
      text-align: left;
      text-decoration: none;
      padding: 0;
      &:link,
      &:visited {
        color: $color__magenta;
      }
      &:hover,
      &:focus,
      &:active {
        color: $color__blue;
      }
    }
  }
  > li:last-of-type {
    border-bottom: none;
  }
}
.navbar-collapse {
  position: absolute;
  top: 100%;
  height: 1000vh;
  overflow: hidden;
  background-color: rgba(0, 0, 0, 0.7);
  > .nav {
    background-color: $color__ltgrayblue;
  }
}

#menu-main li.mobileonly + li {
  display: none;
}
@media only screen and (min-width: 62em) {
  /* 992 =================================================== */
  .navbar-default .navbar-nav {
    margin: 1.0625em 0 0.3125em;
    > li {
      border-bottom: none;
      padding: 0;
      border-right: 1px solid $color__blue;
      > a {
        @include font-size(25/16);
        @include font-size(18/16);
        line-height: 0.72;
        line-height: 0.88888888888889;
        text-transform: uppercase;
        font-family: "HelveticaNeueLTStd-BdCn";
        color: $color__magenta;
        text-align: left;
        text-decoration: none;
        padding: 0 0.4em;
        padding: 0 0.55555555555556em;
        //border-right: 1px solid $color__blue;
        &:link,
        &:visited {
          color: $color__magenta;
        }
        &:hover,
        &:focus,
        &:active {
          color: $color__yellow;
        }
        > i {
          display: none;
        }
      }
    }
    > li:last-child {
      border-right: none;
      a {
        border-right: none;
      }
    }
  }

  #menu-main li.mobileonly {
    display: none;
    + li {
      display: block;
    }
  }
}
#menu-main > li {
  text-align: left;
  > a {
    display: inline-block;
    width: 100%;
  }
  .fa {
    float: right;
    color: $color__yellow;

    @include font-size(19/16);
    line-height: 4;
  }
}
.sub-menu {
  padding-left: 0;
  display: none;
  li {
    text-align: left;
    margin-bottom: 1em;
  }
  a {
    text-decoration: none;
    text-transform: uppercase;
    @include font-size(22.5/16);
    @include font-size(18/16);
    font-family: "HelveticaNeueLTStd-MdCn";
    &:link,
    &:visited {
      color: $color__blue;
    }
    &:hover,
    &:focus,
    &:active {
      color: $color__magenta;
    }
  }
}

@media only screen and (min-width: 62em) {
  /* 992 =================================================== */
  .give-cambia-a-shot #menu-main > li:nth-of-type(2),
  .how-to-take-cambia #menu-main > li:nth-of-type(3),
  .proven-technology #menu-main > li:nth-of-type(4),
  .rip-it #menu-main > li:nth-of-type(5),
  .get-in-touch #menu-main > li:nth-of-type(5) {
    padding: 0 0.625em;
    a {
      border-bottom: 2px solid $color__yellow;
      padding: 0;
    }
  }
  .how-to-take-cambia #menu-main > li:nth-of-type(3) {
    padding: 0 0.625em;
    a {
      border-bottom: 2px solid $color__yellow;
      padding: 0;
    }
  }
}
