#formContents {}

#formConfirm {}

.mktoForm {
    float: left;
    width: 100%!important;
    padding: 0;

    .mktoRequiredField .mktoAsterix {
        display: none!important;
        float: none;
        padding-left: 0;
        padding-right: 5px;
        position: absolute;
        right: 100%;
    }

    select,
    input {
        width: 100%!important;
        font: normal 14px $font-family-sans-serif !important;
    }

    label {
        margin: 25px 0 10px!important;
        color: $text-color;
        width: 100%!important;
    }
    .mktoFieldWrap {
        position: relative;
        width: 100%;

        .mktoError {
            .mktoErrorMsg {
                display: block;
                margin-top: 7px;
                background-color: none;
                background-image: none;
                background-image: none;
                background-image: none;
                background-image: none;
                border: 1px solid #9f1300;
                -webkit-border-radius: 0;
                border-radius: 0;
                -webkit-box-shadow: none;
                box-shadow: none;
                color: #f3f3f3;
                font-size: 1em;
                line-height: 1.2em;
                max-width: 16em;
                padding: 0.4em 0.6em;
                text-shadow: #901100 0 -1px 0;
            }
        }
    }

    .mktoFormRow {
        width: 90%;
        max-width: 400px;
        float: none;
        margin-left: 0px;
        @media (min-width: $screen-xs) {
            width: 100%;
        }

        @media (min-width: $screen-md) {
            // margin-left: 0px;
            width: 50%;
            float: left;
            padding-right: 3%;
        }
        .mktoGutter,
        .mktoOffset {
            display: none;
        }
    }
    .mktoFormCol {
        float: none;
        width: 100%;
    }
    .mktoButtonRow {
        padding: 30px 0 30px 0!important;
        float: left!important;
        width: 100%!important;
        >.mktoButtonWrap {
            margin-left: 0px !important;
        }
        .mktoButton {
            width: auto!important;
            height: auto !important;
            line-height: 1!important;
            padding: 10px 25px!important;
            font: 700 16px $font-family-sans-serif !important;
            text-shadow: none !important;
            background-color: $brand-cta !important;
            color: #fff !important;
            background-image: none !important;
            border: none !important;
            border-radius: 0px !important;
        }
    }
}