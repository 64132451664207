// Differently colored bullets from text
%color-bullet {
    &:not(.gform_fields):not(.gfield_checkbox) {
        padding: 0 0 0 15px;
        list-style-type: none;
        li {
            line-height: 0.5em;
            padding: 5px 0 5px 0;
            position: relative;
            font-size: $font-size-base;
            &:before {
                content: "\2022";
                display: inline-block;
                position: absolute;
                font-size: 1.3em;
                line-height: .5em;
                left: -10px;
                top: 5px;
                color: $brand-primary;
            }
        }
    }
}

main {
    // Default to colored list bullets
    ul {
        &:not(.purpleBullet) {
            @extend %color-bullet;
        }
    }
}


sup {
    line-height: 0;
    font-size: 55%;
    top: -.8em;
    &.different {
        top: 0px;
        font-size: 100%;
    }
}

a {
    text-decoration: underline;
    &:hover {
        text-decoration: none;
    }
}

// Responsive video - common js function dependent on .videoWrapper & sub iframe style
.videoWrapper {
    position: relative;
    padding-bottom: 56.25%;
    /* 16:9 */
    padding-top: 25px;
    height: 0;
    iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}

.footnote-size{
    font-size: 1rem !important;
    display: block;
}