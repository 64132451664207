html {
  font-size: 16px;
  scroll-behavior: smooth;
}
body {
  letter-spacing: -0.5px;
}
a {
  color: $color__blue;
  &:link,
  &:visited {
    color: $color__blue;
  }
  &:hover,
  &:focus,
  &:active {
    color: $color__magenta;
  }
}
img {
  height: auto !important;
  max-width: 100%;
}
#isi .expand-isi-content,
.container,
.isi-container {
  padding: 0 13px;
}
.small-case {
  text-transform: lowercase !important;
}
.main {
  color: $color__black;
  margin: 0 0.90625em;
  padding-top: 5.625em;
  .yellowbar {
    margin: 1em 0 1.5625em;
    display: flex;
    &:before {
      content: " ";
      min-width: 6px;
      background-color: $color__yellow;
      border-radius: 10px;
    }
    h1 {
      margin-bottom: -4px;
      span {
        color: $color__yellow;
      }
    }
    h3 {
      color: $color__white;
      text-transform: uppercase;
    }
  }
  h1 {
    @include font-size(40/16);
    line-height: 1.0375;
    font-family: "UniversLTStd-UltraCn";
    margin: 0;
    padding-left: 11px;
    text-transform: uppercase;
    color: $color__magenta;
  }
  h2 {
    @include font-size(22.5/16);
    line-height: 1.0666666666667;
    font-family: "HelveticaNeueLTStd-BdCn";
    margin: 0 0 5px;
    color: $color__magenta;
    text-transform: uppercase;
  }
  h3 {
    @include font-size(21/16);
    line-height: 1;
    font-family: "HelveticaNeueLTStd-BdCn";
    margin: 0 0 0.95238095238095em;
    color: $color__blue;
    span {
      color: $color__blue;
    }
  }
  h4 {
    @include font-size(21/16);
    line-height: 1.0566037735849;
    font-family: "HelveticaNeueLTStd-Cn";
  }
  .img-background {
    //@include background-2x( '/images/DiagonalLines_300px_tile', 'png', 300px, 300px, center 33px, repeat-x );
    background: transparent url(/images/DiagonalLines_300px_tile.svg) repeat-x
      center 33px;
    background-size: 300px 300px;
    text-align: center;
    margin-bottom: 1.25em;
    .mobileonly {
      display: block;
      margin: 0 auto;
    }
    .desktoponly {
      display: none;
      margin: 0 auto;
    }
  }
  .snap {
    margin-bottom: 12.5vh;
    margin-bottom: 2em;
    text-align: center;
    h2,
    h3,
    h4,
    h5,
    li,
    .references {
      text-align: left;
    }
  }
  #safety-and-tolerability .snap {
    margin-bottom: 0;
  }
  .snap-sub {
    display: flex;
    align-content: flex-start;
    &:before {
      content: " ";
      color: $color__magenta;
      border: 2px solid $color__magenta;
      border-radius: 100%;
      font-size: 30.5px;
      line-height: 40px;
      width: 40px;
      min-width: 40px;
      max-width: 40px;
      height: 40px;
      text-align: center;
      margin-right: 11px;
      font-family: "UniversLTStd-UltraCn";
      display: block;
    }
    h3 {
      padding-bottom: 0.47619047619048em;
      border-bottom: 1px solid $color__magenta;
      margin-bottom: 0.47619047619048em;
    }
  }
  .snap1 .snap-sub:before {
    content: "1";
  }
  .snap2 .snap-sub:before {
    content: "2";
  }
  .snap3 .snap-sub:before {
    content: "3";
  }
  .snap4,
  .snap5,
  .snap6,
  .snap7 {
    h3 {
      @include font-size(20/16);
      line-height: 1;
      text-align: center;
      margin-bottom: 0.375em;
      position: relative;
      text-transform: uppercase;
      &:after {
        content: " ";
        border-top: 1px solid $color__blue;
        height: 1px;
        position: absolute;
        top: 50%;
        bottom: 50%;
        left: 0;
        right: 0;
        z-index: 0;
      }
      span {
        display: inline-block;
        background-color: $color__white;
        padding: 0 0.375em;
        position: relative;
        z-index: 10;
      }
    }
  }
  .snap4 {
    margin-bottom: 100px;
  }
  ul {
    margin: 1.25em 0 2.5em;
    li {
      font-family: "HelveticaNeueLTStd-Cn";
      line-height: 1.0625 !important;
      padding: 0 0 0 10px !important;
      margin-bottom: 10px;
    }
  }
}
.section-panel {
  //padding-top: 100px;
}

@media only screen and (min-width: 62em) {
  /* 992 =================================================== */
  .main {
    margin: 6.03125em auto 0;
    padding: 3.25em 2.8125em 0;

    h1 {
      @include font-size(4);
      line-height: 0.9375;
    }
    .yellowbar {
      width: 100%;
      h1 {
        padding: 0.15625em 0 0.15625em 0.15625em;
        br {
          display: none;
        }
      }
    }
    h2,
    h3 {
      @include font-size(40/16);
      line-height: 1.025;
    }
    .img-background {
      background-image: url(/images/DiagonalLines_300px_tile_DKTP.svg);
      background-repeat: repeat;
      margin-right: 3.59375em;
      float: left;
      background-size: 150px 150px;
      .mobileonly {
        display: none;
      }
      .desktoponly {
        display: block;
      }
    }
    .snap {
      clear: both;
    }
    .snap4,
    .snap5,
    .snap6,
    .snap7,
    .snap8 {
      h3 {
        @include font-size(32/16);
        line-height: 1.03125;
        color: $color__blue;
        span {
          color: $color__blue;
        }
      }
    }
    .snap4,
    .snap5 {
      width: 50%;
      float: left;
      clear: none;
      margin-bottom: 0;
    }
    .snap6 {
      h3 span br {
        display: none;
      }
    }
    .snap8 {
      img,
      ul {
        float: left;
        max-width: 50%;
        li {
          font-size: 29px !important;
          font-size: 1.1825rem !important;
        }
      }
      ul {
        margin: 3.75em 0;
      }
      + .button {
        float: right;
      }
    }
    .snap-sub {
      h3 {
        padding: 0.5em 0;
        margin-bottom: 0.625em;
      }
      h4 {
        @include font-size(35/16);
        line-height: 1.0285714285714;
        width: 100%;
      }
    }
    .snap-sub:before {
      font-size: 70px;
      border-width: 4px;
      width: 89px;
      height: 89px;
      min-width: 89px;
      max-width: 89px;
      line-height: 89px;
    }
  }
}

.graybox {
  background-color: $color__ltgrayblue;
  margin: 0 -1.71875em 2em;
  padding: 1.40625em 0.9375em 0.9375em;
  h2 {
    padding: 0 0.625em;
    @include font-size(20/16);
    line-height: 1.05;
    text-transform: none;
    display: flex;
    margin-bottom: 0.25em;
    i {
      color: $color__yellow;
      text-align: center;
      @include font-size(15/16);
      flex: 0 0 2em;
    }
  }
  h2.chevron-container:last-of-type {
    margin-bottom: 1.40625em;
  }
}
@media only screen and (min-width: 62em) {
  /* 992 =================================================== */
  .graybox {
    padding: 0;
    margin: 0 0 2em;
    display: flex;
    align-items: center;
    > .chevron-outer {
      float: right;
      width: 334px;
      order: 1;
    }
    h2 {
      //@include font-size(40/16);
      //line-height: 1.075;
      @include font-size(26/16);
      margin: 4.25em 0 1em;
      margin: 0 0 1em;
      i {
        top: 5px;
        position: relative;
        @include font-size(20/16);
      }
      + h2 {
        //margin: 0 0 4.25em;
        i {
          top: 4px;
          position: relative;
        }
      }
    }
  }
}

.waterbox {
  padding: 0.9375em 0 0.9375em 4.21875em;
  //@include background-2x( '/images/2_0-Wtr_drps_MBL', 'png', 50px, 54px, left center, no-repeat );
  background: transparent url(/images/2_0-Wtr_drps_MBL.svg) no-repeat left
    center;
  background-size: 50px 54px;
  h2 {
    color: $color__blue;
    @include font-size(20/16);
    line-height: 1.05;
    text-transform: none;
  }
}
@media only screen and (min-width: 62em) {
  /* 992 =================================================== */
  .waterbox {
    background-image: url(/images/2_0-Wtr_drps_DKTP.svg);
    background-size: 102.5px 112.5px;
    padding: 4.0625em 0 3.0625em 7.5em;
    h2 {
      //@include font-size(4.125);
      //line-height: 1.0909090909091;
    }
  }
}

.how-to-take-cambia {
  main img {
    margin: 0 auto;
    display: block;
    &.molecule {
      margin: 0 0 0.6875em;
    }
  }
  .button {
    margin: 27.5px 0 0;
  }
  .button + .button {
    margin: 7.5px 0 27.5px;
  }
}

.proven-technology {
  .main {
    ul {
      margin-bottom: 0.625em;
      > li:last-child {
        margin-bottom: 0;
      }
    }
    h2 {
      margin-bottom: 0.44444444444444em;
    }
    h3 {
      margin-bottom: 0.47619047619048em;
    }
    h4 {
      margin: 0 0 0.5em;
    }
  }
  .snap > div {
    margin-bottom: 3.125em;
  }
  .snap2 {
    img {
      display: block;
      margin: 1.875em auto 0.625em;
    }
  }
}
/*These ems are based on a 16px base*/
@media only screen and (min-width: 62em) {
  /* 992 =================================================== */
  .how-to-take-cambia {
    .button {
      text-align: left;
      + .button {
        text-align: right;
        line-height: 2;
      }
    }
  }
  .proven-technology {
    .cols {
      > div {
        width: calc(50% - 0.625em);
        float: left;
        padding-right: 0.625em;
      }
      > div:nth-of-type(even) {
        padding-left: 0.625em;
        padding-right: 0;
      }
    }
    .snap {
      //display: flex;
      h2,
      h3 {
        @include font-size(1.8125);
        line-height: 1.1034482758621;
        margin-bottom: 0.3448275862069em;
        padding: 0;
        border-bottom: none;
      }
      ul li {
        font-size: 24px !important;
        font-size: 1.5rem !important;
        line-height: 1.1034482758621;
        margin-bottom: 0.41666666666667em;
      }
    }
    .img-background + div {
      flex: 1;
    }
    .snap-sub {
      flex-wrap: wrap;
      h2.desktoponly {
        flex: 0 0 100%;
        order: 0;
      }
      h3.desktoponly {
        flex: 0 0 100%;
        order: 1;
        padding: 0;
        border-bottom: 0;
      }
      > div {
        order: 3;
        flex: 1;
      }
    }
    .snap-sub:before {
      order: 2;
    }
    .snap2 {
      > div {
        margin-bottom: 0;
      }
      > div > div {
        display: flex;
        align-items: center;
      }
      .button {
        text-align: left;
        margin-right: 3.125em;
        margin: 1.8125em 3.125em 1.875em 0;
      }
      img {
        margin-right: 1.5em;
        margin: 0 1.5em 0 0;
      }
    }
    .proven-button {
      float: left;
      line-height: 1;
      text-align: center;
      text-align: right;
      span {
        display: block;
      }
      + .proven-button {
        float: right;
        text-align: left;
        span .fa {
          margin-right: 0;
          margin-left: 10px;
        }
      }
    }
  }
}

/*These ems are based on a 16px base*/
@media only screen and (min-width: 62em) {
  /* 992 =================================================== */
  .rip-it {
    .snap {
      margin-bottom: 1.25em;
      display: flex;
      justify-content: center;
      h3 + h3 {
        @include font-size(20/16);
      }
    }
    .img-background {
      flex: 0 0 auto;
      margin-bottom: 0;
      display: flex;
      justify-content: center;
      flex-direction: column;
      + div {
        align-self: flex-start;
      }
    }
    .button {
      margin: 0;
      line-height: 1;
      text-align: left;
      span {
        display: block;
      }
    }
    .nolist {
      column-count: 2;
    }
  }
}
/*These ems are based on a 16px base*/
@media only screen and (min-width: 62em) {
  /* 992 =================================================== */
  .how-to-take-cambia .button {
    line-height: 1;
    span {
      display: block;
    }
  }
  .how-to-take-cambia .button,
  .how-to-take-cambia .button + .button {
    margin: 3.125em 0;
    line-height: 1;
  }
  .lmabutton .fa {
    margin-left: 10px;
    margin-right: 0;
  }
}

.give-cambia-a-shot {
  .main {
    h4 {
      //margin: 0 0 0.95238095238095em;
    }
  }
}

/*These ems are based on a 16px base*/
@media only screen and (min-width: 62em) {
  /* 992 =================================================== */
  .give-cambia-a-shot,
  .rip-it {
    .snap {
      h2,
      h3 {
        @include font-size(1.8125);
        line-height: 1.1034482758621;
        margin-bottom: 0.3448275862069em;
        padding: 0;
        border-bottom: none;
      }
      ul li,
      h4 {
        font-size: 24px !important;
        font-size: 1.5rem !important;
        line-height: 1.1034482758621;
        margin-bottom: 0.41666666666667em;
      }
    }
  }
  .how-to-take-cambia {
    .snap3 {
      h2,
      h3 {
        @include font-size(1.8125);
        line-height: 1.1034482758621;
        margin-bottom: 0.3448275862069em;
        padding: 0;
        border-bottom: none;
      }
      ul li,
      h4 {
        font-size: 24px !important;
        font-size: 1.5rem !important;
        line-height: 1.1034482758621;
        margin-bottom: 0.41666666666667em;
      }
    }
  }
  .how-to-take-cambia {
    .snap3 {
      display: flex;
      .img-background {
        flex: 0 0 auto;
      }
    }
  }
  .give-cambia-a-shot {
    .snap1,
    .snap2,
    .snap3 {
      display: flex;
      .img-background {
        flex: 0 0 auto;
      }
    }
    .snap4,
    .snap5 {
      width: 100%;
      float: none;
      clear: both;
      margin-bottom: 12.5vh;
      margin-bottom: 2em;
    }
  }
  .button {
    text-align: left;
    span {
      display: block;
    }
  }
  .htt-button .fa {
    margin-right: 0;
    margin-left: 10px;
  }
  .give-cambia-a-shot .snap5 {
    margin-bottom: 0;
  }
}

.home {
  div[role="document"] {
    @include background-2x(
      "/images/0_0-Vid_Stl_Home_MBL_BG",
      "png",
      375px,
      300px,
      center center,
      no-repeat
    );

    background-size: cover;
  }
  .main {
    color: $color__white;
    .yellowbar {
      margin: 1.71875em 0;
      margin: 3.75em 0;
    }
    h1 {
      line-height: 1;
      margin: 0;
      color: $color__white;
      @include font-size(49/16);
    }
    h2 {
      @include font-size(26/16);
      line-height: 1.0384615384615;
      font-family: "HelveticaNeueLTStd-Cn";
      margin: 0;
      padding-left: 11px;
      color: $color__white;
      text-transform: none;
      a {
        font-family: "HelveticaNeueLTStd-BdCn";
      }
    }
    h3 {
      padding-left: 11px;
      font-family: "HelveticaNeueLTStd-Cn";
      margin-bottom: 0.26190476190476em;
    }
  }
  .button {
    min-width: 9.4791666666667em;
    &.videobutton {
      display: none;
    }
  }
}
@media only screen and (min-width: 62em) {
  /* 992 =================================================== */
  .home {
    div[role="document"] {
      background-image: url(/images/0_0-Vid_Stl_Home_DKTP_BG.png);
      background-position: center 96px;
      padding: 0;
      width: 100%;
      > .main {
        margin: 6.03125em auto 0;
        padding: 6.03125em 13px 0;
        padding: 2em 13px 0 43.75em;

        max-width: 1170px;
        min-height: 39.5625em;
      }
    }
    .main {
      position: relative;
      .yellowbar {
        margin: 0 0 2.1875em;
      }
      h1 {
        @include font-size(52/16);
        font-family: "UniversLTStd-LightUltraCn";
        font-family: "UniversLTStd-UltraCn";
      }
      h3 {
        @include font-size(20/16);
        font-family: "HelveticaNeueLTStd-MdCn";
      }
      .references {
        width: 100%;
        position: absolute;
        top: 100%;
        margin: 0;
        left: 0;
        right: 0;
      }
    }
    .button {
      margin-bottom: 0;
      min-width: 15.208333333333em;
      //padding: .4375em;
      text-align: center;
      margin: 0;
      float: left !important;
      + .button {
        margin-top: 0.625em;
      }
      .fa {
        @include font-size(22/16);
      }
      span {
        display: inline-block;
      }
    }
    .button.videobutton {
      display: block;
      margin-bottom: 1.25em;
      &.odd {
        background: rgb(147, 17, 110);
        background: linear-gradient(
          180deg,
          rgba(147, 17, 110, 1) 0%,
          rgba(125, 10, 92, 1) 50%,
          rgba(104, 3, 76, 1) 100%
        );
      }
      .fa {
        color: $color__yellow;
        @include font-size(19/16);
      }
    }
  }
}

.references {
  font-family: "HelveticaNeueLTStd-Cn";
  background-color: $color__white;
  color: $color__black;
  padding: 0.578125em 27.5px;
  margin: 0 -27.5px;
  clear: both;
  &.bottom {
    margin-bottom: 3.125em;
  }
  p {
    @include font-size(13/16);
    line-height: 1.1153846153846;
    margin-bottom: 7.5px;
  }
  ul li {
    font-size: 13px !important;
    font-size: 0.8125rem !important;
    line-height: 1.1153846153846 !important;
    margin-bottom: 7.5px;
  }
  p:last-child,
  ul:last-child {
    margin: 0;
  }
  strong {
    font-family: "HelveticaNeueLTStd-BdCn";
    font-weight: 400;
  }
  .home & {
    background: $color__gray;
  }
  &.hanging-asterisk {
    padding-bottom: 2.578125em;
    p {
      display: flex;
    }
  }
  &.references-spaced {
    h5,
    h6 {
      font-family: "HelveticaNeueLTStd-BdCn";
      @include font-size(13/16);
      font-weight: 400;
      margin: 0 0 1.1538461538462em;
      color: $color__magenta;
    }
    h6 {
      color: $color__black;
      text-transform: uppercase;
      margin: 0;
    }
    p,
    ul {
      margin: 0 0 1.1538461538462em;
    }
    .nolist {
      padding-left: 0 !important;
      list-style: none;
      li {
        margin-bottom: 0;
        padding-left: 0 !important;
        &:before {
          content: none !important;
        }
      }
    }
  }
}
/*These ems are based on a 16px base*/
@media only screen and (min-width: 62em) {
  /* 992 =================================================== */
  .references,
  .references.references-spaced {
    p,
    h5,
    h6 {
      @include font-size(16/16);
    }
    ul li {
      font-size: 16px !important;
      font-size: 1rem !important;
    }
  }
}

.proven-technology {
  .main {
    .snap3 {
      .snap-sub {
        flex-wrap: wrap;
        > div {
          flex: 1;
        }
        > .references {
          flex: 1 1 100%;
        }
      }
      .snap-sub:before {
        flex: 1 1 40px;
      }
    }
  }
}

.video-trigger-2-0 {
  position: relative;
  text-align: center;
  display: block;
  .fa {
    width: 358px;
    height: 238px;
    color: rgba(0, 0, 0, 0.25);
    text-align: center;
    display: block;
    @include font-size(80/16);
    line-height: 2.8625;
    margin: auto;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    position: absolute;
    z-index: 10;
  }
  img {
    display: block;
    z-index: 9;
  }
}
@media only screen and (min-width: 62em) {
  /* 992 =================================================== */
  .video-trigger-2-0 {
    float: left;
    .fa {
      width: 720px;
      height: 403px;
      @include font-size(11.375);
      line-height: 2.2142857142857;
    }
  }
}

.mobileonly {
  display: block !important;
  &.textlink {
    display: inline !important;
  }
}
.desktoponly {
  display: none !important;
}
@media only screen and (min-width: 62em) {
  /* 992 =================================================== */
  .mobileonly {
    display: none !important;
    &.textlink {
      display: none !important;
    }
  }
  .desktoponly {
    display: block !important;
    &.textlink {
      display: inline !important;
    }
  }
}

.image-grid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: 1.25em 0;
  > div {
    width: 160px;
    h3 {
      color: $color__magenta;
      text-align: center !important;
    }
  }
}
#shot {
  background: transparent url(/images/1_0-shot_cup_MBL.svg) no-repeat 0% 50%;
  background-size: 37px 39px;
  padding-left: 2.1428571428571em;
  font-family: "HelveticaNeueLTStd-BdCn";
}
@media only screen and (min-width: 62em) {
  /* 992 =================================================== */
  .image-grid {
    > div {
      width: 25%;
    }
  }
  #shot {
    background-image: url(/images/1_0-shot_cup_DKTP.svg);
    background-size: 45px 47px;
    line-height: 1.6206896551724;
  }
}
h4.hanging-asterisk {
  display: flex;
}
.graygrid {
  .gridbox {
    background-color: $color__ltgrayblue;
    text-align: center;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 0.625em;
    padding: 0.625em;
    .imgshift {
      background: transparent url(/images/DiagonalLines_300px_tile.svg)
        no-repeat 50% 50%;
      width: 50px;
      height: 50px;
      border-radius: 100%;
      background-size: 150px 150px;
      img {
        left: -2px;
        top: -2px;
        position: relative;
      }
    }
    h2,
    h3,
    h4 {
      text-align: center;
    }
    h2 {
      font-family: "UniversLTStd-UltraCn";
      @include font-size(20/16);
      line-height: 0.825;
      font-weight: 400;
      border-bottom: 1px solid $color__magenta;
      display: inline-block;
      margin-bottom: 0.45em;
    }
    h3 {
      @include font-size(24/16);
      margin-bottom: 0.125em;
      span {
        padding: 0;
        background-color: transparent;
      }
    }
    h4 {
      font-family: "HelveticaNeueLTStd-BdCn";
      @include font-size(10.5/16);
      line-height: 1.5714285714286;
      font-weight: 400;
      margin: 0;
    }
    > div {
      flex: 1 0 auto;
      max-width: 160px;
      &.imgshift {
        flex: 0 0 50px;
      }
    }
  }
  h3 {
    &:after {
      content: none !important;
    }
  }
}
.proven-technology .main {
  h3 + ul li,
  h4 + ul li {
    font-size: 21px !important;
    font-size: 1.3125rem !important;
  }
}
.rip-it {
  .main {
    h3 {
      span {
        color: $color__magenta;
      }
    }
    .references {
      a {
        color: $color__blue;
        &:link,
        &:visited {
          color: $color__blue;
        }
        &:hover,
        &:focus,
        &:active {
        }
      }
    }
  }
}
main ul:not(.gform_fields):not(.gfield_checkbox):not(.purpleBullet) li:before {
  top: 3px;
}

.molecule {
  margin-bottom: 0.6875em;
}
@media only screen and (min-width: 62em) {
  /* 992 =================================================== */
  .graygrid {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 0.625em;
    .gridbox {
      width: calc(50% - 0.3125em);
      margin-right: 0.3125em;
      margin-bottom: 0.625em;
      padding: 2.5em;
      .imgshift {
        width: 136.5px;
        height: 136.5px;
        background-size: 300px 300px;
        background-position: 47% 50%;
        img {
          left: -4px;
          top: -4px;
        }
      }
      > div {
        max-width: 285px;
      }
      > div.imgshift {
        flex: 0 0 136.5px;
      }
      h2 {
        @include font-size(29/16);
      }
      h3 {
        @include font-size(36/16);
        line-height: 1em;
        span {
          display: block;
        }
      }
      h4 {
        line-height: 1;
      }
    }
    .gridbox:nth-of-type(even) {
      margin-left: 0.3125em;
      margin-right: 0;
    }
  }
  .molecule {
    margin: 0 auto 0.6875em;
    + h3 {
      text-align: center !important;
    }
  }
}

.hashedbar {
  margin: 1em 0 1.5625em;
  background: transparent url(/images/DiagonalLines_300px_tile.svg) repeat 50%
    50%;
  background-size: 300px 300px;
  padding: 1em 2.5em 0.5em;
  h1 {
    padding-left: 0;
    text-transform: none;
    text-align: left;
  }
  + h4 {
    margin: 0 0 0.85714285714286em 1.9047619047619em;
    a {
      display: block;
      text-decoration: none;
      &:link,
      &:visited {
        color: $color__magenta;
      }
      &:hover,
      &:focus,
      &:active {
        color: $color__magentagradientdark;
      }
    }
    + h4 {
      margin: 0 0 0.85714285714286em 1.9047619047619em;
      a {
        display: block;
        text-decoration: none;
        &:link,
        &:visited {
          color: $color__magenta;
        }
        &:hover,
        &:focus,
        &:active {
          color: $color__magentagradientdark;
        }
      }
    }
  }
}

@media only screen and (min-width: 62em) {
  /* 992 =================================================== */
  // Button styling
  .one-liner {
    line-height: 2.875 !important;
    padding-top: 0;
    padding-bottom: 0;
    span {
      display: inline !important;
    }
  }
}

.mixwithwater {
  margin-bottom: 0 !important;
}
.mixwithwater + .references {
  margin-bottom: 1.25em;
}
@media only screen and (min-width: 62em) {
  /* 992 =================================================== */
  .mixwithwater + .references {
    margin-bottom: 0.625em;
    p {
      text-align: center;
    }
  }
}

#popup .boxed {
  border: 2px solid $color__black;
  padding: 1em 0.5em;
  font-family: "HelveticaNeueLTStd-BdCn";
  margin-bottom: 1em;
  p {
    margin-bottom: 11px;
    display: block;
    font-family: "HelveticaNeueLTStd-BdCn";
    font-size: 9px;
  }
  ul:last-child,
  p:last-child {
    margin-bottom: 0;
  }
}
@media only screen and (min-width: 62em) {
  /* 992 =================================================== */
  #popup .boxed p {
    font-size: 16px;
  }
}

.noclear {
  clear: right;
}

#popup {
  h2 {
    color: #fff;
    line-height: 1.0384615384615;
    font-family: HelveticaNeueLTStd-Cn;
    margin: 0;
    text-transform: none;
  }

  .button {
    text-align: center;
    min-width: 150px;
    margin-bottom: 0;
    font-size: 1.125rem;
    @media only screen and (min-width: 62em) {
      margin: 0;
      margin-top: 0.83333333333333em;
      font-size: 1.5rem;
    }
  }
}

h4.hanging-asterisk.whatever {
  font-size: 1rem !important;
}

.cove-telehealth {
  .button {
    min-width: 268px;
    float: none;
    text-align: center;
    margin: 20px auto;
    width: auto;
    background: #284f99;
    background: linear-gradient(to bottom, #284f99 0, #003c72 100%);
    &:hover,
    &:focus,
    &:active {
      background: #284f99;
    }
  }

  img {
    &.cove_lg {
      margin: 20px 0;
      display: block;
    }
  }

  .main {
    ul {
      margin-bottom: 0.625em;
      > li:last-child {
        margin-bottom: 0;
      }
    }
    h2 {
      margin-bottom: 0.44444444444444em;
    }
    h3 {
      margin-bottom: 0.47619047619048em;
    }
    h4 {
      margin: 0 0 0.5em;
    }
  }

  .snap {
    //display: flex;
    h2,
    h3 {
      @include font-size(1.8125);
      line-height: 1.1034482758621;
      margin-bottom: 0.3448275862069em;
      padding: 0;
      border-bottom: none;
    }
    ul li {
      font-size: 24px !important;
      font-size: 1.5rem !important;
      line-height: 1.1034482758621;
      margin-bottom: 0.41666666666667em;
    }
  }

  .img-background + div {
    flex: 1;
  }

  .snap-sub {
    flex-wrap: wrap;
    h2.desktoponly {
      flex: 0 0 100%;
      order: 0;
    }
    h3.desktoponly {
      flex: 0 0 100%;
      order: 1;
      padding: 0;
      border-bottom: 0;
    }
    > div {
      order: 3;
      flex: 1;
    }
  }
  .snap-sub:before {
    order: 2;
  }
  .snap2 {
    > div {
      margin-bottom: 0;
    }
    > div > div {
      display: flex;
      align-items: center;
    }
    .button {
      text-align: left;
      margin-right: 3.125em;
      margin: 1.8125em 3.125em 1.875em 0;
    }
    img {
      margin-right: 1.5em;
      margin: 0 1.5em 0 0;
    }
    p {
      font-size: 1.25em;
      text-align: left;
    }
  }
  .proven-button {
    float: left;
    line-height: 1;
    text-align: center;
    text-align: right;
    span {
      display: block;
    }
    + .proven-button {
      float: right;
      text-align: left;
      span .fa {
        margin-right: 0;
        margin-left: 10px;
      }
    }
  }
  .snap > div {
    margin-bottom: 3.125em;
  }

  .container_cols {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .item {
    flex: 1 auto;
    height: 280px;
    margin-bottom: 3%;
    background-color: #f4f6fa;
    padding-top: 0.6875em;
    background: #f4f6fa url(/images/DiagonalLines_300px_tile.svg) repeat center
      bottom;
    background-size: 300px 840px;
    text-align: center;
    color: #284f99;

    h4 {
      padding: 0 30px;
      font-weight: bold;
      sup {
        font-weight: bold;
      }
    }

    img {
      padding: 30px 0;
    }

    .button {
      min-width: 268px;
      float: none;
      text-align: center;
      margin: 20px auto;
      width: auto;
      background: rgb(147, 17, 110);
      background: linear-gradient(
        180deg,
        rgba(147, 17, 110, 1) 0%,
        rgba(125, 10, 92, 1) 50%,
        rgba(104, 3, 76, 1) 100%
      );
      &:hover,
      &:focus,
      &:active {
        background: rgb(147, 17, 110);
      }
    }
  }
}

@media only screen and (min-width: 992px) {
  .cove-telehealth .item {
    flex: 0 32%;
  }
}

@media only screen and (max-width: 768px) {
  .save-callout {
    flex-direction: column;
  }
  .get-in-touch .save-callout > div {
    width: 100%;
    padding: 10px;
    text-align: center;
  }

  .save-callout > div:first-child {
    margin-right: 0px;
  }

  .save-callout a {
    margin: 0 auto;
  }
}
