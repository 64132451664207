// Glyphicons font path
$icon-font-path: "../fonts/";


// Grid settings
$main-sm-columns: 12;
$sidebar-sm-columns: 4;


// Colors
$color__white: #fff;
$color__black: #000;
$color__magenta: #92016e;
$color__blue: #284f99;
$color__yellow: #ebb533;
$color__gray: #939598;
$color__ltgrayblue: #f4f6fa;
$color__magentagradientdark: #74045a;
$color__bluegradientdark: #003c72;



$brand-primary: $color__magenta;
$brand-secondary: #27ae60;
$brand-cta: #3EB1C8;

$body-bg: #fff;
$text-color: #505050;
$text-color: $color__black;




// FONTS
$font-family-sans-serif: 'HelveticaNeueLTStd-LtCn', "Helvetica Neue",
Helvetica,
Arial,
sans-serif;
$font-family-base: $font-family-sans-serif;

$font-family-serif: 'UniversLTStd-UltraCn', "Helvetica Neue",
Helvetica,
Arial,
sans-serif;
$font-family-base:        $font-family-serif;
$font-size-base: 16px;


$link-color: $color__blue;