// DO NOT REMOVE
// FOR RESPONSIVE JS BREAKPOINT READER
// REQUIRES Sage.breakpoint.refreshValue();
body {
  &:before {
    content: "0";
    display: none;
  }
}
@media (min-width: $screen-xs) {
  body {
    &:before {content: "1";}
  }
}
@media (min-width: $screen-sm) {
  body {
    &:before {content: "2";}
  }
}
@media (min-width: $screen-md) {
  body {
    &:before {content: "3";}
  }
}
@media (min-width: $screen-lg) {
  body {
    &:before {content: "4";}
  }
}