h4 {
  margin-top: 5px;
  margin-bottom: 5px;
}
#isi {
  #isi_sticky {
    visibility: hidden;
    position: fixed;
    z-index: 999;
    width: 100%;
    height: 25vh;
    @media (max-width: $screen-sm) {
      height: 43vh;
    }
    min-height: 170px;
    background-color: $body-bg;
    padding-top: 0.6875em;
    //@include background-2x( '/images/DiagonalLines_300px_tile', 'png', 300px, 300px, center top, repeat );
    background: $color__white url(/images/DiagonalLines_300px_tile.svg) repeat
      center bottom;
    background-size: 300px 300px;
    .sticky-isi,
    .sticky_content {
      height: 100%;
      background-color: $color__white;
    }

    .isi-wrapper {
      padding: 10px 15px;
      position: relative;
      @media (min-width: $screen-sm) {
        padding: 10px 0px;
      }
      padding-top: 0 !important;
    }
    .sticky-isi {
      border-bottom-left-radius: unset;
      box-shadow: unset;
    }
    &.show {
      visibility: visible;
      .toggle-isi:not(i) {
        display: block;
      }
    }
    .scroll-container {
      position: relative;
      height: 21.5vh;
      overflow: auto;
      @include clearfix();
    }
  }

  .expand_isi {
    background-color: $color__white;
    color: $color__magenta;
    overflow: hidden;
    height: auto;
  }

  .expand_isi_text {
    //padding: 0 3vw;
    //padding: 0 14.5px;
    //font-size: 3.4vw;
    //letter-spacing: -0.05em;
    //line-height: 32px;
    color: $color__magenta;
    //font-weight: 700;
    font-family: 'UniversLTStd-UltraCn';
    margin-bottom: 0px;
    margin-top: 10px;
    &:hover {
      cursor: pointer;
      .expand_isi_view {
        color: $color__black;
      }
    }
    @media (min-width: $screen-xs) {
      /*
            font-size: 16px;
            letter-spacing: -0.05em;
            padding: 0 15px;
*/
    }
    @media (min-width: $screen-sm) {
      letter-spacing: initial;
      padding: 0;
    }
    .yellowbar {
      margin-top: 20px;
      display: flex;
      &:before {
        content: ' ';
        min-width: 4px;
        background-color: $color__yellow;
        border-radius: 10px;
        margin-right: 4px;
      }
    }
    .isi-expand-title-text {
      text-transform: uppercase;
      @include font-size(22.5/16);
      font-family: 'UniversLTStd-UltraCn';
      padding: 0.17777777777778em 0 0;
      float: left;
      clear: both;
      line-height: 1;
    }
    span {
      //display: none;
      @media (min-width: $screen-sm) {
        display: inline-block;
      }
    }
  }
  .expand_isi_view {
    //height: 30px;
    line-height: 1;
    text-align: center;
    color: $color__gray;
    float: right;
    cursor: pointer;
    @include font-size(13/16);
    transition: color 0.3s ease;
    //padding: 0.23076923076923em 0;
    border: 1px solid;
    padding: 5px 20px;
    font-weight: bold;
    letter-spacing: 1px;
    i {
      color: inherit;
      padding-left: 0.5em;
      position: relative;
      &:before {
        top: 0;
        left: 0;
      }

      @media (min-width: $screen-sm) {
        //padding-left: 5px;
      }
      @media (min-width: $screen-md) {
        //padding-right: 0px;
      }
    }
    &:hover {
      color: $color__black;
    }
  }

  .expand-isi-content {
    @extend .container;
    span.view-full {
      display: none;
      @media (min-width: $screen-xs) {
        display: inline-block;
      }
    }
  }
}

#isi-border {
  padding-top: 0.75em;
  //@include background-2x( '/images/DiagonalLines_300px_tile', 'png', 300px, 300px, center top, repeat );
  background: $color__white url(/images/DiagonalLines_300px_tile.svg) repeat
    center bottom;
  background-size: 300px 300px;
}

.isi-container {
  @extend .container;

  &.clone_target {
    padding: 30px 0px;
    padding-left: 27.5px;
    padding-right: 27.5px;
    background-color: $color__white;
    @media (min-width: $screen-sm) {
      padding-left: 0;
      padding-right: 0;
    }
  }
  .isi-content {
    line-height: 1.3;
    margin: auto;
    width: 100%;
    padding: 0;
    font-family: 'HelveticaNeueLTStd-Cn';
    .yellowbar {
      margin-bottom: 20px;
      display: flex;
      &:before {
        content: ' ';
        min-width: 4px;
        background-color: $color__yellow;
        border-radius: 10px;
        margin-right: 4px;
      }
      .isi-expand-title-text {
        text-transform: uppercase;
        @include font-size(22.5/16);
        font-family: 'UniversLTStd-UltraCn';
        padding: 0.17777777777778em 0 0;
        float: left;
        clear: both;
        line-height: 1;
        color: $color__magenta;
      }
    }
    a {
      text-decoration: underline;
      &:link,
      &:visited {
        color: $color__blue;
      }
      &:hover,
      &:focus,
      &:active {
        color: $color__magenta;
      }
    }
  }
  strong {
    font-weight: 400;
    font-family: 'HelveticaNeueLTStd-BdCn';
    color: $color__magenta;
    &.alt {
      color: $color__black;
    }
  }
  ul {
    padding-left: 1em;
  }
  p + ul {
    margin-top: -0.5em;
  }
}
.boxed {
  border: 2px solid $color__black;
  padding: 1em 0.5em;
  font-family: 'HelveticaNeueLTStd-BdCn';
  margin-bottom: 1em;

  ul:last-child,
  p:last-child {
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 48em) {
  /* 768 =================================================== */
  #isi-container {
    width: 100%;
    .scroll-container {
      max-width: 1170px;
      margin: 0 auto;
    }
  }
  #isi_sticky {
    .l {
      float: left;
      margin-right: 0.5em;
     // width: calc(50% - 0.5em);
      clear: left;
    }
    .boxed {
      float: right;
      //margin-left: 0.5em;
     // width: calc(50% - 0.5em);
      clear: right;
    }
  }
  #isi,
  #isi-border {
    p,
    li {
      @include font-size(18/16);
    }
  }
  #isi .expand_isi_text .isi-expand-title-text,
  .isi-container .isi-content .yellowbar .isi-expand-title-text {
    @include font-size(28/16);
  }
  .isi-container ul {
    padding-left: 1.2em;
  }
}

.sticky_content h4:first-child {
  margin-top: -10px !important;
}
.sticky_content .scroll-container {
  overflow: visible !important;
}
.sticky_content p {
  margin-bottom: 5px;
  line-height: 1em;
}

.isi_titlehead{
  padding-bottom: 5px;
  font-size: 1.5em !important;
}
