.logo-footer {
	margin-bottom: 0.9375em;
}

.content-info {
	padding-top: 1.125em;
	//@include background-2x( '/images/DiagonalLines_300px_tile', 'svg', 300px, 300px, center top, repeat );
	background: $color__white url(/images/DiagonalLines_300px_tile.svg) repeat center bottom;
	background-size: 300px 300px;
	 margin: 0;
	 .container {
		 padding: 0;
	 }
	.information,
	.references {
		background-color: $color__white !important;
		padding: 1.5625em 1.71875em;
		margin: 0;
	}
}

.menu-footer {
	background-color: $color__magenta;
	padding: 10px 1.71875em;
/*
	display: flex;
	align-items: center;
	justify-content: space-between;
*/
	a {
		text-decoration: none;
		@include font-size(13/16);
		line-height: 2.0769230769231;
		font-family: 'HelveticaNeueCE-Roman';
		display: block;
		&:link,
		&:visited {
			color: $color__white;
		}
		&:hover,
		&:focus,
		&:active {
			color: $color__yellow;
		}
	}
}




#exit-intent-modal,
#popup-modal {
	display: none;
	background-color: rgba(0,0,0,0.85);
	width: 100vw;
	height: 100vh;
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	z-index: 1000;
}
#exit-intent {
	width: 270px;
	height: 136px;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	margin: auto;
	padding-top: 0.75em;
	//@include background-2x( '/images/DiagonalLines_300px_tile', 'svg', 300px, 300px, center top, repeat );
	background-color: $color__ltgrayblue;
	background: $color__ltgrayblue url(/images/DiagonalLines_300px_tile.svg) repeat center bottom;
	background-size: 300px 300px;
	> div {
		padding: 1.71875em 0.9375em 0.9375em;
		background-color: $color__ltgrayblue;
	}
	
	h1 {
		font-weight: 400;
		@include font-size(22.5/16);
		line-height: 1;
		text-align: center;
		color: $color__blue;
		margin: 0 0 1em;
		font-family: 'HelveticaNeueLTStd-BdCn';
	}
	.button-wrapper {
		display: flex;
		justify-content: space-between;
		margin: 0 auto;
	}
	.button {
/*
		background-color: #02475d;
		border: 2px solid #006890;
		color: #fff;
		text-decoration: none;
		border-radius: 3px;
		text-transform: uppercase;
		width: 111px;
		font-size: 14px;
		display: block;
		text-align: center;
		line-height: 1;
		padding: 5px 0 4px;
*/
		min-width: auto;
		width: 110px;
		outline: none !important;
		margin-bottom: 0;
		@include font-size(21/16);
		padding: 0.14285714285714em;
		color: $color__white;
		background-color: $color__magenta;
		background: linear-gradient(to bottom, $color__magenta 0%, $color__magentagradientdark 100%);
		line-height: 1;
		&:hover {
 			background: $color__magenta;
			cursor: pointer;
		}
	}
}




@media only screen and (min-width: 62em) {
  /* 992 =================================================== */
  .content-info {
	  padding-top: 0;
	  > .container {
		  display: flex;
	  }
	  .information {
		  float: left;
	  }
  }
  .menu-footer {
	  text-align: right;
	  flex: 1;
  }
  #exit-intent {
	  width: 32.8125em;
	  height: 16.5625em;
	  width: 26.25em;
	  height: 13.75em;
	  display: flex;
	  > div {
		  width: 100%;
		  padding: 5em 6.875em;
		  padding: 3.75em;
	  }
	  .button {
		  text-align: center;
		  margin-top: 1.9047619047619em;
	  }
	  h1 {
		  margin-bottom: 0;
	  }
  }
}

#popup-modal {
	display: block;
}
#popup {
	background: $color__white url(/images/0_Modal_Mgnta_Popup_wave_MBL.svg) no-repeat 100% 0%;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	width: 311.5px;
	height: 235px;
	height: 412px;
	margin: auto;
	z-index: 1010;
	padding: 22.5px 10px 10px;
	.popup-content {
		text-align: center;
		margin-bottom: 1.25em;
		h2 {
			padding-left: 0;
			//@include font-size(1.90625);
			img {
				float: left;
				position: relative;
				left: -20px;
				margin-right: -20px;
				width: 157px;
			}
			span {
				text-transform: uppercase;
				color: $color__yellow;
			}
			strong {
				font-family: 'HelveticaNeueLTStd-BdCn';
				font-weight: 400;
			}
		}
		hr {
			margin: 10px 0;
			+ h2 {
				font-family: 'UniversLTStd-UltraCn';
			}
		}
	}
	.popup-references {
		color: $color__black;
		clear: both;
		p {
			display: flex;
			font-family: 'HelveticaNeueLTStd-Cn';
			@include font-size(9/16);
			margin-bottom: 0;
			line-height: 1;
		 a {
			  text-decoration: none;
			  color: $color__black;
			  &:link,
			  &:visited {
				  color: $color__black;
			  }
			  &:hover,
			  &:focus,
			  &:active {
				  color: $color__blue;
			  }
		  }
		}
	}
	.button {
		min-width: 150px;
		float: right;
		margin-bottom: 0;
		@include font-size(18/16);
		.fa {
			color: $color__yellow;
			@include font-size(14/16);
		}
	}
}
#popup-exit {
	text-indent: -9999em;
	background: transparent url(/images/0_Modal_X-button.svg) no-repeat 50% 50%;
	background-size: cover;
	width: 15px;
	height: 15px;
	position: absolute;
	top: -3px;
	right: -3px;
}

@media only screen and (min-width: 62em) {
  /* 992 =================================================== */
  #popup {
	  background-image: url(/images/0_Modal_Mgnta_Popup_DKTP.svg);
	  width: 740px;
	  height: 510px;
	  height: 585px;
	  height: 685px;
	  padding: 56px 25px 25px; 
	  .popup-content {
		 	margin-bottom: 5.5em;
		  h2 {
			  @include font-size(44/16);
			  span {
				  @include font-size(60/16);
				  display: block;
			  }
			  img {
				  left: -50px;
				  margin-right: -50px;
				  width: 370px;
			  }
		  }
		  hr {
			  border-top-width: 2px;
			  margin: 20px 0;
			  + h2 {
				  @include font-size(60/16);
			  }
			  + h2 span {
				  display: inline;
				  @include font-size(60/16)
			  }
		  }
	  }
	  .button {
		  @include font-size(24/16);
		  float: right !important;
		  margin-top: 0.83333333333333em;
		  .fa {
			  @include font-size(19/16);
		  }
	  }
	  .popup-references {
		  p {
			  //@include font-size(16/16);
		  }
	  }
  }
  #popup-exit {
	  background-image: url(/images/0_Modal_X-button_DKTP.svg);
	  width: 35px;
	  height: 35px;
	  top: -10px;
	  right: -10px;
  }
}

.isi-page {
	#isi,
	#isi-border {
		display: none;
	}
	p {
		text-align: left;
	}
	main ul:not(.gform_fields):not(.gfield_checkbox):not(.purpleBullet) {
		padding-left: 1.2em;
		list-style-type: disc;
		ul {
			list-style-type: circle;
		}
	}
	main ul:not(.gform_fields):not(.gfield_checkbox):not(.purpleBullet) li {
		padding-left: 0 !important;
	}
	main ul:not(.gform_fields):not(.gfield_checkbox):not(.purpleBullet) li:before {
		content: none;
	}
}