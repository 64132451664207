button,
.button {
	color: $color__white;
	text-decoration: none;
	background-color: $color__magenta;
	@include font-size(24/16);
	line-height: 1;
	display: inline-block;
	margin-bottom: 27.5px;
	text-transform: uppercase;
	clear: both;
	float: left;
	font-family: 'HelveticaNeueLTStd-BdCn';
	//width: 9.4791666666667em;
	min-width: 227.5px;
	min-width: 320px;
	//line-height: 45px;
	text-align: center;
	padding: 0.4375em;
	
	&:link,
	&:visited {
		color: $color__white;
		background-color: $color__blue;
		background: linear-gradient(to bottom, $color__blue 0%, $color__bluegradientdark 100%);
	}
	&:hover,
	&:focus,
	&:active {
		background: $color__blue;
	}
	span {
		color: $color__yellow;
	}	
	.fa {
		@include font-size(19/16);
		position: relative;
		top: -1px;
		margin-left: 0;
		margin-right: 10px;
		color: $color__yellow;
	}
	span + .fa {
		color: $color__yellow;
		margin-left: 10px;
		margin-right: 0;
	}
}
.home .button .fa {
	color: $color__white;
}
.home .button.videobutton .fa {
	color: $color__yellow;
}

@media only screen and (min-width: 62em) {
  /* 992 =================================================== */
  .button,
  button {
	  width: 15.208333333333em;
	  text-align: left;
	  padding: .4375em 2.1666666666667em;
	  //padding: .4375em 2.9166666666667em;
	  clear: none;
	  margin: 3.125em 0;
	  float: right;
	  text-align: center;
	  + .button {
		  text-align: right;
		  float: left;
	  }
  }
}
